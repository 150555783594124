import React from "react"

import Layout from "./../../components/layout"
import SEO from "./../../components/seo"

const News = () => (
  <Layout>
    <SEO title="News" />

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <u>
          <h5 className="h2 text-uppercase text-underline font-weight-bold text-center">
            H.E Paul Kagame Speech on growth of education system.
          </h5>
        </u>
        <div className="text-center">
          <img
            src="https://3.bp.blogspot.com/-LLzxkaHbLis/XLRrX-eWUaI/AAAAAAAAAXw/wv30DQL9lL0MVWgfqV1cq9f9FaDOyH06QCLcBGAs/s640/kagame.jpg"
            className="img-responsive"
            alt="..."
          />
        </div>
        <div className="py-8 justify-content-between">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-10 order-lg-1">
              <p className="h3 font-weight-bold">
                The republic of Rwanda has a critical role and responsibility to
                educate and care for its citizens. In the past years, Rwanda has
                experienced several policy changes in the education sector. The
                government of Rwanda has been on the forefront of tackling
                issues that may affect education in Rwanda.
              </p>
              <p className=" my-4">
                Study in Rwanda works hand in hand with government bodies to
                ensure our policies are in line with the government policies.
                Study in Rwanda is already combining efforts with various
                institutions to fulfill its mandate.
              </p>
              <p className="my-4">
                Follow a speech by His Excellency Paul Kagame motivating
                citizens to work towards growth of the education system. The
                speech has motivated agencies like Study in Rwanda as well as
                other concerned bodies to work towards making Rwanda’s education
                taking the lead in Africa.
              </p>
              <i>
                <p className="my-4">
                  &quot;If our country ranks first in Africa, and the whole
                  world is watching us today, it is not because we have the
                  Vatican, the Kabba, the White House, the Elysee or the Taj
                  Mahal here at home.
                </p>
                <p className="my-4">
                  No more because you have a certain Paul Kagame as Head of
                  State, but rather because you have sons and daughters of
                  Rwanda in this country.
                </p>
                <p className="my-4">
                  Men and especially young people and Rwandan women who have
                  forgiven each other, and who have taken the destiny of their
                  country in their hands, by the meaning of work, innovations
                  and patriotism as key to their progress and development . What
                  we are doing here in Rwanda is not a miracle, nor is it
                  impossible elsewhere, it is simply the commitment of an entire
                  nation, especially Rwandan youth and women, but also political
                  will.
                </p>
                <p className="my-4">
                  Soon, we will have the best universities in the world in our
                  country (NYU, Harvard, London School of Economics, MIT ...) we
                  will also have the best hospitals in Rwanda. The intelligence
                  and competence of our children will be equal to or greater
                  than those of the great powers, and our country Rwanda will be
                  a new door of opportunity, knowledge, technology and
                  innovation for all African children and the world who want to
                  learn from us or better still bring us their knowledge.
                </p>
                <p className="my-4">
                  Rwanda will not only be at the rendezvous of civilizations,
                  but also of science and technology and all the cultures that
                  will dominate the world.
                </p>
                <p className="my-4">
                  Today, more than 40% of our agriculture is being dominated by
                  technology, Africa as a whole can do better than us for
                  essential food self-sufficiency, it is all about national
                  interest and the African people.
                </p>
                <p className="my-4">
                  Although we are now far from our past, let us make good use of
                  the present to hit the future. Because the most difficult
                  thing is no longer where we come from, but rather where we
                  want and dream to go. In Rwanda, we desire and dream to go far
                  thanks to these people and to this now conscious youth”
                </p>
              </i>
              <p className="my-4">
                Follow the Link,{" "}
                <a
                  target="_blank"
                  href="https://everyhournng.blogspot.com/2019/04/leadership-quotes-
paul-kagame-on-radio.html"
                >
                  https://everyhournng.blogspot.com/2019/04/leadership-quotes-
                  paul-kagame-on-radio.html
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default News
